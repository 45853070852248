import "../css/app.css";
import "./bootstrap";

import { createInertiaApp } from "@inertiajs/vue3";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { createApp, h } from "vue";
import { ZiggyVue } from "../../vendor/tightenco/ziggy";

import "vue3-toastify/dist/index.css";
import Toastify from "vue3-toastify";

// Inertia.on('error', (errors) => {
//   if (errors.status === 403) toast.error('You are not authorized to perform this action.')
//   if (errors.status === 404) toast.error('The requested resource was not found.')
//   if (errors.status === 500) toast.error('Server error. Please try again later.')
// })

const appName = import.meta.env.VITE_APP_NAME || "Laravel";
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

createInertiaApp({
    title: (title) => `${title} | ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./pages/${name}.vue`,
            import.meta.glob("./pages/**/*.vue"),
        ),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .use(Toastify, {
                autoClose: 3000,
                position: "top-right",
            })
            .use(pinia)
            .mount(el);
    },
    progress: {
        color: "#4B5563",
    },
});
